@use "variables" as variables;

/* You can add global styles to this file, and also import other style files */
// @charset "utf-8";

// =====================================
// VtoV用のボタン
// =====================================
.vtov-btn {
  background-color: variables.$button-vtov-color !important;
  color: variables.$button-vtov-text-color !important;
}

.vtov-btn:hover:not(:disabled) {
  background-color: #0056b3;
}

.vtov-btn:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  opacity: 0.7;
}

// =====================================
// キャンセルボタン
// =====================================
.cancel-btn {
  background-color: variables.$button-cancel-color !important;
  color: variables.$button-cancel-text-color !important;
}

// リードオンリーの情報を表示するためのボタン
.btn-readonly {
  display: block;
  background-color: variables.$button-readonly-color;
  color: variables.$button-readonly-text-color;
  border-color: variables.$button-readonly-color;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: variables.$button-readonly-hover-color;
  }
}

// 情報を編集するためのボタン
.btn-edit {
  background-color: variables.$button-edit-color;
  color: variables.$button-edit-text-color;
  border-color: variables.$button-edit-border-color;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: variables.$button-edit-hover-color;
  }
}

// ユーザに次の行動をしてもらうためのボタンのスタイル
.btn-next-action {
  background-color: variables.$button-next-action-color;
  color: variables.$button-next-action-text-color;
  border-color: variables.$button-next-action-color;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: variables.$button-next-action-hover-color;
  }
}

// .btn-end-round-early {
//   color: variables.$button-next-action-color;
// }

.btn-bgcolor-login {
  // background-color: variables.$button-login-background-color !important;
  // color: #fff !important;
  // border-color: variables.$button-login-background-color !important;
  // transition: background-color 0.2s ease-out;
  // &:hover {
  //   background-color: variables.$button-login-background-hover-color !important;
  // }
}

.btn-bgcolor-register {
  // background-color: variables.$button-register-background-color !important;
  // color: #fff !important;
  // border-color: variables.$button-register-background-color !important;
  // transition: background-color 0.2s ease-out;
  // &:hover {
  //   background-color: variables.$button-login-background-hover-color !important;
  // }
}
