/* You can add global styles to this file, and also import other style files */
@charset "utf-8";

@use "mixins" as mixins;
@use "variables" as variables;
@use "buttons" as buttons;

// ngx-bootstrap をインストールしたらこのファイル末尾に自動で追加されたが、
// body のスタイルが上書きされるのでここに移動した。
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

*,
*::before,
*::after {
  box-sizing: border-box; // 全ての要素にbox-sizing: border-boxを適用
}

// iPhoneの場合は、フッターの高さを調整する
[data-device="iphone"] .app-container {
  // background-color: orange;
  padding-bottom: calc(env(safe-area-inset-bottom) + 44px);
}

// =================================
// iPhoneの場合は、フッターの高さを調整する
// =================================
.iphone-padding-bottom-spacer {
  height: 0; // デフォルトは0
}

// iPhoneの場合、画面下にpaddingを入れる
[data-device="iphone"] .iphone-padding-bottom-spacer {
  height: variables.$iphone-padding-bottom-spacer-height;
  background-color: variables.$iphone-padding-bottom-spacer-background-color;

  // テキスト表示のための追加スタイル
  display: flex;
  align-items: center;
  justify-content: center;
  color: variables.$iphone-padding-bottom-spacer-text-color; // テキストの色
  font-size: variables.$iphone-padding-bottom-spacer-text-size; // テキストのサイズ
}

// =================================
// スクロールバーのカスタマイズ
// =================================

/* スクロールバーのカスタマイズ */
::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* スクロールバーの背景色 */
}

::-webkit-scrollbar-thumb {
  background: #888; /* スクロールバーのつまみ色 */
  border-radius: 6px; /* つまみの角を丸く */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* ホバー時の色 */
}

html,
body {
  // overscroll-behavior: none;
  // scroll-behavior: smooth;
}

html {
  // scroll-behavior: auto;
  // background-color: #ffffff;
  /* フォント */
  // font-family: "Noto Sans JP", sans-serif;
}

body {
  font-family: "Noto Sans JP", "Helvetica Neue", sans-serif;
  // font-family: "Helvetica Neue", Helvetica, Arial, "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック",
  //   "Yu Gothic", sans-serif;
  // max-width: 1200px;
  margin: 0;
  margin-left: auto; /* 左側マージンを自動的に空ける */
  margin-right: auto; /* 右側マージンを自動的に空ける */
  text-align: left; /* 中身を左側表示に戻す */
  // width: 650px; /* 幅を決定する */
  // max-width: 1024px;
  // background-color: #f6f8fa;
  // background-color: variables.$body-background-color;

  // モーダルを表示した時に、スクロールバーが非表示になっても横幅が一定になるようにする
  // min-width: 100vw;
  // overflow-y: scroll; // 常に垂直方向のスクロールバーを表示する
}

// =================================
// mainのスタイル
// =================================
main {
  // border-top: 1px solid #ccc;
  // border-bottom: 1px solid #ccc;
  // padding: 10px;

  // padding: 15px;
  // padding-left: 4px;
  // padding-right: 4px;
}

a {
  color: #1a0dab;
}

/* ヘッダー上部のキャプション */
// $caption-size: 14px;
.caption {
  margin-top: 10px;
  margin-bottom: 7px;
  text-align: center;
  font-weight: bold;
  color: variables.$caption-color;
  font-size: variables.$caption-size;
}

/* ヘッダーイメージ */
.header-image {
  display: flex;
  justify-content: center;
}

.footer {
  // background-color: rgb(180, 180, 180);
  height: 30px;
}

.zoom-icon {
  a {
    margin: 0;
    // padding: 0;
    img {
      width: 40px;
    }
  }
}

// Angular Stepper の送信ボタンのスタイル
button.submit-button {
  background-color: orange;
}

// Angular Stepper に共通する設定
// ステッパーの一番上の数字など
.mat-horizontal-stepper-header-container {
  display: none !important;
}

.mat-stepper-wrapper {
  display: flex;
  justify-content: center;
}

.mat-step-header {
  // display: none !important;
}

// コンポーネント固有のスタイルファイル例：custom-mat-form-field.component.scss
.custom-mat-form-field {
  // width: 500px; // `mat-form-field`の幅を100%に設定
  //   height: 25px;
  margin-right: 16px;
  input[matInput] {
    width: 100%; // `input`要素の幅も100%に設定
  }
}

// テンプレートのレイアウト

// 文字の色
.text-red {
  color: red;
}

// -----------------------------------
// フォームのエラーメッセージのスタイル

.error-input {
  border-color: red;
}

.frontend-error-message {
  color: red;
  // font-size: 0.8rem;
  // margin-top: 5px;
  span {
    color: red;
  }
}

.backend-error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

// ==================================
// コンペの管理画面
// ==================================
// コンペの画像ファイルを表示するコンテナ
.image-container {
}
.image-item {
  img {
    max-width: 300px;
  }
}

// ==================================
// クリック可能なテキスト
// ==================================
.clickable-text {
  cursor: pointer;
  color: #1a0dab;
  font-weight: bold;
  text-decoration: underline;
}

.blank-minus {
  text-align: center;
}

// ==================================
// モーダルの文字の強調
// ==================================
.emphasize {
  color: red;
  font-weight: bold;
}

.selected-row {
  background-color: #a2d2ff !important; // !importantを使って強制適用
}

.bidder-row {
  background-color: #ffc8dd !important;
}

// ==================================
// ボタンのスタイル
// ==================================
// ---------------------------
// bootstrapのボタンのスタイル
// ---------------------------
// disabledされたボタン
.btn:disabled {
  color: #9a9a9a;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  cursor: not-allowed;
}

.disabled {
  background-color: #e0e0e0;
  color: #999;
  // border: 1px solid #ccc;
  cursor: not-allowed;

  // disabled内の全ての要素に適用
  * {
    background-color: #e0e0e0;
    color: #999;
    cursor: not-allowed;
  }
}

// ===============================
// ログインページとユーザ登録のページ
// ===============================
.heading {
  margin: 10px 0 !important;
  font-weight: bold !important;
}

.form-group label {
  margin-left: 5px;
  text-align: left;
  display: block;
}

.none {
  display: none;
}

// iPhoneの場合は、コンテンツの高さを調整する
.iphone-content-105vh {
  // background-color: red;
  min-height: 100vh;
  // 画面の高さが700px以下の場合は、コンテンツの高さを105vhにする
  @media screen and (max-height: 700px) {
    min-height: 105vh;
  }
}

// TODO: 後で消す
.overlay-test {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: skyblue;
}
