// =====================================
// サイト全体に関わるスタイル
// =====================================
// モバイル表示のフォントサイズ
$mobile_font_size: 12px; // モバイルの標準のフォントサイズ
$mobile_font_size_small: 11px;
$mobile_font_size_unit: 14px;

// body
$body-background-color: #f6f8fa;

// ヘッダーの色
$header-back: #fff;
// 検索ボタンのボーダーの色
$border-glay-color: #e4e4e4;

// $base-color: #c6538c;
$caption-size: 13px;
$caption-color: #958e93;

// ボタン型のラジオボタンの色
$text-color: black;
// $text-color: #fff;
$primary-color: #0d6efd;

// =====================================
// ボタンのスタイル
// =====================================
// ------------------------------
// リードオンリーの情報を表示するためのボタン
// ------------------------------
$button-readonly-color: #0d6efd;
$button-readonly-hover-color: #0b5ed7;
// $button-readonly-color: #198754;
// $button-readonly-hover-color: #146c43;
$button-readonly-text-color: #fff;
// ------------------------------
// 編集用ボタン
// ------------------------------
$button-edit-color: #fff;
$button-edit-border-color: #ff4a33;
$button-edit-hover-color: #ff9d90;
// $button-readonly-color: #198754;
// $button-readonly-hover-color: #146c43;
$button-edit-text-color: #000;
// ------------------------------
// 次の行動をしてもらうためのボタン
// ------------------------------
$button-next-action-color: #ff4a33;
$button-next-action-hover-color: #ce3b30;
$button-next-action-text-color: #fff;
// ------------------
// ログイン用のボタン
// ------------------
$button-login-background-color: #ff4a33;
$button-login-background-hover-color: #ce3b30;
// ------------------
// ユーザ登録用のボタン
// ------------------
$button-register-background-color: #ff4a33;
$button-register-background-hover-color: #ce3b30;

// =====================================
// トップページのカードのスタイル
// =====================================
// カード内のカテゴリなどへのリンクの色
$card_border-color: #e0e0e0;
$card_border-radius: 8px; // カードの輪郭のradius
$card_category_radius: 32px;

$category-wrapper_a_color: #666666;
$category-wrapper_a_background-color: #f6f8fa;
$mobile_card__info__title_font-size: 14px; // カードのタイトル
$mobile_font-size-enlarge: 14px; // カードのタイトル
// =====================================
// コンペの詳細ページのスタイル
// =====================================

// =====================================
// ボタンのスタイル
// =====================================
// 通常のボタン
$button-vtov-color: #bb9a54;
$button-vtov-text-color: #fff;
// キャンセルボタン
$button-cancel-color: #e4e4e4;
$button-cancel-text-color: #000;

// =====================================
// チャットの吹き出しのスタイル
// =====================================
// 自分の吹き出しの色
$self-bubble-color: #bb9a54;
// 自分の未送信の吹き出しの色
$self-unsent-bubble-color: #e3c993;
// 相手の吹き出しの色
$other-bubble-color: #ceb784;
// 相手の未送信の吹き出しの色
$other-unsent-bubble-color: #d7c6a2;

// =====================================
// z-indexの設定
// =====================================
$z-index-error-message: 9999; // エラーメッセージのz-index
$z-index-debug-error-button: 9999; // デバッグ用のエラーボタンのz-index
$z-index-loading: 9998; // 読み込み中を表すコンポーネントのz-index
// ------------------------------
// mainのz-index
// ------------------------------
$z-index-main: 1;
// ------------------------------
// 透明のスクロール用のz-index
// ------------------------------
$z-index-scroll-to-top-area: 10; // スクロール位置が48px以上の時に表示
// ------------------------------
// レビュー用のz-index
// ------------------------------
$z-index-review-tabs-overlay: 4; // レビュータブのオーバーレイのz-index
// ------------------------------
// 動画プレイヤー用のz-index
// ------------------------------
$z-index-public-swipeable-hls-player: 1; // パブリックなスワイプ可能な動画プレイヤーのz-index
$z-index-video-reply-overlay: 2; // 動画返信用のオーバーレイのz-index
// ------------------------------
// チャット画面の動画プレイヤー用のz-index
// ------------------------------
$z-index-auth-hls-player-and-chat-no-route: 1; // 動画とチャットのオーバーレイのz-index
$z-index-check-my-video-overlay: 2; // 自分の動画のオーバーレイのz-index
// $z-index-video-controler-overlay: 2; // 動画コントローラーのオーバーレイのz-index

// =====================================
// iPhone用のスタイル
// =====================================
$iphone-padding-bottom-spacer-background-color: rgb(
  0,
  255,
  136
); // iPhoneの場合、画面下にpaddingを入れる

// =====================================
// 各要素の背景色
// =====================================
// $background-color-main: skyblue;
$background-color-main: orange;
// $background-color-main: purple; // メインの背景色

$chat-background-color: blue; // チャットの背景色
$video-background-color: black; // 動画の背景色

// =====================================
// レイアウトに関する設定
// =====================================
// ヘッダーとフッターの高さ
$navigation-header-height: 60px;
$mobile-footer-height: 50px;
// =====================================
// ダミーの要素のスタイル
// =====================================
$dummy-container-height: 0px;
$dummy-container-background-color: #e74bff;

// getComputedStyle(document.documentElement)は:rootから変数の値を取得する
:root {
  --dummy-container-height: #{$dummy-container-height};
  --navigation-header-height: #{$navigation-header-height};
  --mobile-footer-height: #{$mobile-footer-height};
}

// =====================================
// 各要素のサイズ
// =====================================
// iPhoneの場合、画面下にpaddingを入れる
$iphone-padding-bottom-spacer-height: calc($mobile-footer-height * 200);

// ==============================================
// ==============================================
// 本番用
// ==============================================
// ==============================================
$background-color-main: transparent;
$iphone-padding-bottom-spacer-background-color: transparent;
$iphone-padding-bottom-spacer-height: calc($mobile-footer-height * 2);
// ナビゲーションヘッダーの背景色
$navigation-header-background-color: transparent;

$chat-background-color: transparent; // チャットの背景色
// iPhoneの場合、画面下にpaddingを入れる
$iphone-padding-bottom-spacer-text-size: 12px; // テキストのサイズ
$iphone-padding-bottom-spacer-text-color: transparent; // テキストの色
